var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { tag: "h3", size: "m" } }, [
        _vm._v(_vm._s(_vm.pageTypes[_vm.page_type]))
      ]),
      _vm.loading
        ? _c("ck-loader")
        : _vm.page_type !== "landing"
        ? _c("ck-select-input", {
            attrs: {
              value: _vm.parent_id,
              id: "parent_id",
              label: "Parent",
              options: _vm.parentOptions,
              error: _vm.errors.get("parent_id")
            },
            on: {
              input: function($event) {
                return _vm.onInput("parent_id", $event)
              }
            }
          })
        : _vm._e(),
      _c("ck-text-input", {
        attrs: {
          value: _vm.title,
          id: "title",
          label: "Title",
          type: "text",
          error: _vm.errors.get("title")
        },
        on: {
          input: function($event) {
            return _vm.onInput("title", $event)
          }
        }
      }),
      _c(
        "ck-text-input",
        {
          attrs: {
            value: _vm.slug,
            id: "slug",
            label: "Unique slug",
            type: "text",
            error: _vm.errors.get("slug")
          },
          on: {
            input: function($event) {
              return _vm.onInput("slug", $event)
            }
          }
        },
        [
          _c(
            "gov-hint",
            { attrs: { slot: "hint", for: "slug" }, slot: "hint" },
            [
              _vm._v("\n      This will be used to access the page."),
              _c("br"),
              _vm._v(
                "\n      e.g. example.com/pages/" + _vm._s(_vm.slug) + "\n    "
              )
            ]
          )
        ],
        1
      ),
      _c("ck-text-input", {
        attrs: {
          value: _vm.excerpt || "",
          id: "excerpt",
          label: "Excerpt",
          type: "text",
          error: _vm.errors.get("excerpt")
        },
        on: {
          input: function($event) {
            return _vm.onInput("excerpt", $event)
          }
        }
      }),
      _c("ck-page-content", {
        attrs: { content: _vm.content, id: "content", errors: _vm.errors },
        on: {
          update: function($event) {
            return _vm.onInput("content", $event)
          }
        }
      }),
      _c("ck-image-input", {
        attrs: {
          id: "image",
          label: "Page Image",
          "existing-url": _vm.existingImageUrl
        },
        on: {
          input: function($event) {
            return _vm.onInput("image_file_id", $event.file_id)
          }
        }
      }),
      _vm.page_type === "landing"
        ? _c(
            "ck-collection-input",
            {
              attrs: {
                value: _vm.pageCollectionIds,
                root: "categories",
                error: _vm.errors.get("collections")
              },
              on: {
                input: function($event) {
                  return _vm.onInput("collections", $event)
                }
              }
            },
            [
              _c("gov-hint", [
                _vm._v("Select the collections to show on the landing page")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("ck-radio-input", {
        attrs: {
          value: _vm.enabled,
          id: "enabled",
          label: "Is the page enabled",
          hint: "Indicates if the page is enabled or disabled",
          options: _vm.enabledOptions,
          error: _vm.errors.get("enabled")
        },
        on: {
          input: function($event) {
            return _vm.onInput("enabled", $event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }